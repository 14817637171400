<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[居家客服]在家做客服，轻松日结</p>
    <div>
      <div class="title">【工作简介】</div>
      <p class="psd">1.根据指定文案和图片发布商品</p>
    </div>
    <div>
      <div class="title">【工作要求】</div>
      <p class="msg">
        <span>1、18周岁以上</span>
        <span>2、工作认真，有耐心，可长期坚持</span>
      </p>
    </div>
    <div>
      <div class="title">【薪资待遇】</div>
      <p class="psd">50-100/次，日结完成累计任务，奖励1000</p>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/jjkf.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="title">温馨提示：</div>
      <p class="psd">
        警惕最近出现的新型刷单诈骗！任何与招聘文案不符的、要求加支付宝好友的、带有返利二字的兼职都不要做！全都是骗子！任务先交钱的都不要理会，不论任何理由与形式！
        信息仅供参考，本平台不构成入职建议，是否报名由自己决定，风险自负，请自行评判~若要报名应聘，代表自愿接受风险，平台不承担法律责任
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
